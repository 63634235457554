<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="30%"
    :close-on-click-modal="false"
  >
      <div slot="title">
        Виды коротких замыканий
        <el-button class="back_action_panel--first_element custom-button " @click="handleSave" type="text">
          <SaveIcon /> Сохранить
        </el-button>
    </div>
    <el-form :rules="rules" v-loading="loading" ref="shortCircuitFormForm" :model="shortCircuitForm" label-width="12rem">
      <el-form-item prop="name" label="Наименование:">
        <el-input v-model="shortCircuitForm.name"></el-input>
      </el-form-item>
      <el-form-item prop="code" label="Код:">
        <el-input v-model="shortCircuitForm.code"></el-input>
      </el-form-item>
      <el-form-item prop="actual" label="Признак актуальности:">
        <el-checkbox v-model="shortCircuitForm.actual"/>
      </el-form-item>
      <el-form-item prop="mRid" label="mRid:">
        <el-input v-model="shortCircuitForm.mRid"></el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import shortCircuitFormsApi from '@/api/nsi/shortCircuitForms';
import SaveIcon from '@/assets/icons/save.svg';
import validationRules from '@/mixins/validationRules';

export default {
  name: 'ShortCircuitFormModal',
  props: ['value', 'shortCircuitForm'],
  mixins: [validationRules],
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  components: { SaveIcon },
  data() {
    return {
      loading: false,
      rules: {
        name: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
        code: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
        mRid: [ 
          { validator: this.isUUID, trigger: 'blur' }
        ]
      },
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    async addShortCircuitForm(shortCircuitForm) {
      await shortCircuitFormsApi.addShortCircuitForm(shortCircuitForm);
      this.$emit('on-create');
    },
    async updateShortCircuitForm(shortCircuitForm) {
      await shortCircuitFormsApi.updateShortCircuitForm(shortCircuitForm);
      this.$emit('on-update');
    },
    async validateForm() {
      const valid = await this.$refs.shortCircuitFormForm.validate();
      return valid;
    },
    async handleSave() {
      const valid = await this.validateForm();
      if (!valid) return;

      const request = this.shortCircuitForm.id ? this.updateShortCircuitForm : this.addShortCircuitForm;
      this.loading = true;
      await request(this.shortCircuitForm);
      this.dialogVisible = false;
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
