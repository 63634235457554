<template>
  <div>
    <short-circuit-forms-filter @active-change="filterActive = $event" :filter-model="filterModel" />
    <short-circuit-forms-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>

<script>
import ShortCircuitFormsFilter from '@/views/Nsi/ShortCircuitForms/ShortCircuitFormsFilter';
import ShortCircuitFormsTable from '@/views/Nsi/ShortCircuitForms/ShortCircuitFormsTable';

export default {
  name: 'ShortCircuitForms',
  components: { ShortCircuitFormsTable, ShortCircuitFormsFilter },
  data() {
    return {
      filterModel: {
        nameFilter: ''
      },
      filterActive: true
    };
  }
};
</script>

<style scoped>

</style>
